<div class="layer-controls">
  <span class="layer-title">{{ title() }}</span>
  <div class="layer-status">
    @if (visible() && loading() && canActivate()) {
      <tui-loader></tui-loader>
    }
  </div>
  <input
    tuiSwitch
    type="checkbox"
    [checked]="visible()"
    (change)="toggleLayerVisibility()"
    [disabled]="!canActivate()"
    class="layer-toggle"
  />

  <label
  >Opacity:<input
    autocomplete="off"
    data-lpignore="true"
    #opacityInput
    type="range"
    min="0"
    max="1"
    step="0.01"
    [formControl]="opacityControl"
/></label>
</div>
<div [@slideToggle]="canActivate() ? 'closed' : 'open'" class="warning">
  <tui-notification ttuStatus="warning">
    Zoom in to enable
  </tui-notification>
</div>
