import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, computed, effect, forwardRef, input, model, output } from '@angular/core';
import { Collection, Map as olMap } from 'ol';
import { Translate } from 'ol/interaction';
import { ESKTOOL_TOKEN, EskTool } from '../../util/esk-tool-interface';
import { TranslateEvent } from 'ol/interaction/Translate';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

@Component({
    selector: 'translate',
    templateUrl: './translate.component.html',
    styleUrls: ['./translate.component.css'],
    imports: [CommonModule, TooltipDirective],
    providers: [
        { provide: ESKTOOL_TOKEN, useExisting: forwardRef(() => TranslateComponent) }
    ]
})
export class TranslateComponent implements OnInit, OnDestroy,EskTool{


  active = model(true);

  map = input.required<olMap>();

  layers = input([])

  translateEnd = output<any[]>();

  interaction = computed(() => {

    const layers = this.layers()

    if(layers  == null || layers.length == 0)
      {
        return null;
      }

      const interaction = new Translate({
        layers:this.layers()
      });

      interaction.on('translateend', (event:TranslateEvent)=> {
        this.translateEnd.emit(event.features.getArray());
      });

      return interaction;

  })

  constructor() {

    effect(() => {

      const active = this.active();
      const interaction = this.interaction();

      if(interaction == null)
      {
        return;
      }

        if(!active)
          {
            this.map().removeInteraction(interaction);
            return;
          }

      this.map().addInteraction(interaction);


    })

   }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    const interaction = this.interaction();

    if(interaction != null)
      {
        this.map().removeInteraction(interaction);
      }
    }

  toggleTool()
  {
    this.active.set(!this.active());
  }

}
