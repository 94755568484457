import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule,Validators } from '@angular/forms';
import { FeatureLayer } from 'src/app/map/features/data-catalogue/data-access/models/getcapabilities.model';
import { DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective } from 'src/app/shared/features/dialog';
import { Result, Ok } from 'ts-results';
import { LayerDTO } from '../../data-access/feature-layers.service';

@Component({
    selector: 'clone-feature-layer',
    imports: [DialogComponent, DialogHeaderDirective, DialogFooterDirective, DialogContentDirective, CommonModule, ReactiveFormsModule],
    template: `
<app-dialog (close)="onNoClick()">
  <ng-template eskDialogHeader>
    <h3>Clone Feature Layer</h3>
  </ng-template>

  <ng-template eskDialogFooter>
    <button class="button button--muted button--xs" [disabled]="form.invalid" (click)="onNoClick()">Cancel</button>
    <button class="button button--green-fill button--xs" (click)="onYesClick()">Save</button>
  </ng-template>
  <ng-template eskDialogContent>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="max-w-md mx-auto p-6">

  <div class="mb-4">
    <label for="layerName" class="block text-sm font-medium text-gray-700 mb-1">Layer Name</label>
    <input
      type="text"
      id="layerName"
      formControlName="layerName"
      placeholder="Enter Layer Name"
      class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  </div>
</form>
  </ng-template>
</app-dialog>
`
})
export class CloneFeatureLayerComponent {

  constructor(
    @Inject(DIALOG_DATA) public data:LayerDTO,
    public dialogRef: DialogRef<Result<{
      layerName: string;
  } | null,Error>,CloneFeatureLayerComponent>
  ) {
    dialogRef.disableClose = true;

    this.form.controls.layerName.patchValue(`${data.name} - Copy`)
  }

  form = new FormGroup(
    {
      layerName: new FormControl('', Validators.required)
    }
  )

  onNoClick(): void {
    this.dialogRef.close(Ok(null));
  }

  onYesClick(): void {

    if(!this.form.valid)
      return;

    this.dialogRef.close(Ok(this.form.getRawValue()));
  }
}
