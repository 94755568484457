import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { Control, Zoom } from 'ol/control';
import { Map } from 'ol';
import { always } from 'ol/events/condition';
import { DragZoom } from 'ol/interaction';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

@Component({
    selector: 'map-zoom-area',
    templateUrl: './zoom-area.component.html',
    styleUrls: ['./zoom-area.component.css'],
    imports: [CommonModule, TooltipDirective]
})
export class ZoomAreaComponent {
  @Input() map: Map;

  @ViewChild('zoomAreaButton', { static: true }) zoomAreaButton: ElementRef;

  control: Control;
  interaction: DragZoom;
  active: boolean = false;

  constructor(private elRef: ElementRef, private zone: NgZone) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.interaction = new DragZoom({
      condition: always,
    });

    this.interaction.setActive(this.active);

    this.control = new Control({
      target: this.elRef.nativeElement.parentNode,
      element: this.elRef.nativeElement,
    });

    this.interaction.on('boxend', () => {
      this.zone.run(() => {
        this.active = false;
        this.interaction.setActive(false);
      });
    });

    this.map.addInteraction(this.interaction);
    this.map.addControl(this.control);
  }

  toggle() {
    this.active = !this.active;
    this.interaction.setActive(this.active);
  }

  ngOnDestroy(): void {
    if (this.control == null) return;

    this.map.removeInteraction(this.interaction);
    this.map.removeControl(this.control);
  }
}
