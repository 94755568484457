import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, inject, Directive, Input } from '@angular/core';
import { CHAR_NO_BREAK_SPACE } from '@taiga-ui/cdk/constants';
import { tuiInjectElement } from '@taiga-ui/cdk/utils/dom';
import { tuiWithStyles, tuiPure } from '@taiga-ui/cdk/utils/miscellaneous';
import { TUI_ANIMATIONS_SPEED } from '@taiga-ui/core/tokens';
import { TUI_ANIMATIONS_DEFAULT_DURATION } from '@taiga-ui/core/utils/miscellaneous';
const FADE = [{
  opacity: 0.06
}, {
  opacity: 1
}];
class TuiSkeletonStyles {
  static {
    this.ɵfac = function TuiSkeletonStyles_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSkeletonStyles)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiSkeletonStyles,
      selectors: [["ng-component"]],
      hostAttrs: [1, "tui-skeleton-styles"],
      decls: 0,
      vars: 0,
      template: function TuiSkeletonStyles_Template(rf, ctx) {},
      styles: ["@keyframes tuiSkeleton{0%{opacity:.03}to{opacity:.06}}[tuiSkeleton]._skeleton{color:transparent;background:var(--tui-background-base)!important;box-shadow:none!important;filter:contrast(0) brightness(0);animation:tuiSkeleton ease-in-out 1s infinite alternate;-webkit-user-select:none;user-select:none;pointer-events:none}[tuiSkeleton]._skeleton[data-tui-skeleton]{background:transparent!important}[tuiSkeleton]._skeleton[data-tui-skeleton]:before{content:attr(data-tui-skeleton);background:var(--tui-background-base);font-size:smaller;-webkit-box-decoration-break:clone;box-decoration-break:clone;border-radius:.25rem}[tuiTheme=dark] [tuiSkeleton]._skeleton,[tuiTheme=dark][tuiSkeleton]._skeleton{filter:contrast(0) brightness(0) invert(1)}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSkeletonStyles, [{
    type: Component,
    args: [{
      standalone: true,
      template: '',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'tui-skeleton-styles'
      },
      styles: ["@keyframes tuiSkeleton{0%{opacity:.03}to{opacity:.06}}[tuiSkeleton]._skeleton{color:transparent;background:var(--tui-background-base)!important;box-shadow:none!important;filter:contrast(0) brightness(0);animation:tuiSkeleton ease-in-out 1s infinite alternate;-webkit-user-select:none;user-select:none;pointer-events:none}[tuiSkeleton]._skeleton[data-tui-skeleton]{background:transparent!important}[tuiSkeleton]._skeleton[data-tui-skeleton]:before{content:attr(data-tui-skeleton);background:var(--tui-background-base);font-size:smaller;-webkit-box-decoration-break:clone;box-decoration-break:clone;border-radius:.25rem}[tuiTheme=dark] [tuiSkeleton]._skeleton,[tuiTheme=dark][tuiSkeleton]._skeleton{filter:contrast(0) brightness(0) invert(1)}\n"]
    }]
  }], null, null);
})();
class TuiSkeleton {
  constructor() {
    this.el = tuiInjectElement();
    this.duration = inject(TUI_ANIMATIONS_SPEED) * TUI_ANIMATIONS_DEFAULT_DURATION * 2;
    this.nothing = tuiWithStyles(TuiSkeletonStyles);
    this.tuiSkeleton = false;
  }
  ngOnChanges({
    tuiSkeleton
  }) {
    this.animation?.cancel();
    if (!tuiSkeleton?.currentValue && !tuiSkeleton?.firstChange) {
      this.animation = this.el.animate(FADE, this.duration);
    }
  }
  getPlaceholder(value) {
    switch (typeof value) {
      case 'number':
        return Array.from({
          length: value
        }).map(() => CHAR_NO_BREAK_SPACE.repeat(getLength())).join(' ');
      case 'string':
        return value;
      default:
        return null;
    }
  }
  static {
    this.ɵfac = function TuiSkeleton_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiSkeleton)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiSkeleton,
      selectors: [["", "tuiSkeleton", ""]],
      hostAttrs: ["tuiSkeleton", ""],
      hostVars: 3,
      hostBindings: function TuiSkeleton_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("data-tui-skeleton", ctx.getPlaceholder(ctx.tuiSkeleton));
          i0.ɵɵclassProp("_skeleton", ctx.tuiSkeleton);
        }
      },
      inputs: {
        tuiSkeleton: "tuiSkeleton"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
__decorate([tuiPure], TuiSkeleton.prototype, "getPlaceholder", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSkeleton, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiSkeleton]',
      host: {
        tuiSkeleton: '',
        '[class._skeleton]': 'tuiSkeleton',
        '[attr.data-tui-skeleton]': 'getPlaceholder(tuiSkeleton)'
      }
    }]
  }], null, {
    tuiSkeleton: [{
      type: Input
    }],
    getPlaceholder: []
  });
})();
function getLength() {
  return Math.floor(Math.random() * (15 - 5 + 1)) + 5;
}

/**
 * Generated bundle index. Do not edit.
 */

export { TuiSkeleton };
