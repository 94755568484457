import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, OnInit } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TuiLoader, TuiNotification } from '@taiga-ui/core';
import { TuiSwitch } from '@taiga-ui/kit';
import { Map } from 'ol';
import { load } from 'ol/Image';
import { Vector } from 'ol/layer';
import BaseLayer from 'ol/layer/Base';
import VectorTileLayer from 'ol/layer/VectorTile';
import { combineLatest, debounce, debounceTime, fromEvent, map, merge, scan, startWith, switchMap } from 'rxjs';
import { LayerOpacityDirective } from '../../utils/layer-opacity.directive';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-cadastre-control',
    templateUrl: './cadastre-control.component.html',
    styleUrls: ['./cadastre-control.component.css'],
    imports: [CommonModule, TuiLoader, TuiNotification, TuiSwitch, LayerOpacityDirective, ReactiveFormsModule],
    animations: [
        trigger('slideToggle', [
            state('open', style({
                height: '*',
                opacity: 1,
                overflow: 'hidden'
            })),
            state('closed', style({
                height: '0px',
                opacity: 0,
                overflow: 'hidden'
            })),
            transition('open <=> closed', [
                animate('300ms ease-in-out')
            ])
        ])
    ]
})
export class CadastreControlComponent implements OnInit {


  layer = input.required<VectorTileLayer>();

  map = input.required<Map>();

  hasRequiredZoom = computed(() => {
    const layer = this.layer();

    return layer.getMinZoom() == -Infinity ? false : true
  });

  requiredZoom = computed(() => {
    const layer = this.layer();
    const hasRequiredZoom = this.hasRequiredZoom();

    if(!hasRequiredZoom)
    {
      return -1;
    }

    return layer.getMinZoom();
  })

  currentMapZoom = toSignal(toObservable(this.map).pipe(switchMap(olMap => {
    return fromEvent(olMap, 'moveend').pipe(map(() => {
     return olMap.getView().getZoom()
    }))
  })));

  canActivate = computed(() => {
    const hasRequiredZoom = this.hasRequiredZoom();
    const requiredZoom = this.requiredZoom();
    const currentMapZoom = this.currentMapZoom();



    if(!hasRequiredZoom)
    {
      return true;
    }

    if(currentMapZoom >  requiredZoom)
    {
      return true;
    }

    return false;

  })

   loading = toSignal(toObservable(this.layer).pipe(
    map(layer => layer.getSource()),
    switchMap(source => {
      const tileLoadStart$ = fromEvent(source, 'tileloadstart').pipe(map(() => 1));
      const tileLoadEnd$ = fromEvent(source, 'tileloadend').pipe(map(() => -1));

      return merge(tileLoadStart$, tileLoadEnd$).pipe(
        scan((acc, value) => acc + value, 0),
        startWith(0),
        map(loadingCount => loadingCount > 0)
      );
    })
  ));

  visible = toSignal(toObservable(this.layer).pipe(switchMap(layer => fromEvent(layer,'change:visible').pipe(map(() => layer.getVisible()),startWith(layer.getVisible())))));

  opacity = toSignal(toObservable(this.layer).pipe(switchMap(layer => fromEvent(layer, 'change:opacity').pipe(map(() => layer.getOpacity()),startWith(layer.getOpacity())) )));

  title = computed(() => this.layer().get('title') ?? "UNKNOWN");

  opacityControl = new FormControl();

  constructor() {

    this.opacityControl.valueChanges.pipe().subscribe(opacity => {
      this.layer().setOpacity(opacity);
    })


    effect(() => {

      const canActivate = this.opacity();

      const ctrlValue = this.opacityControl.getRawValue();

      if(ctrlValue == null || ctrlValue != canActivate)
      {
        this.opacityControl.setValue(canActivate,{emitEvent:false});
      }


    })
}

  ngOnInit() {
  }

  toggleLayerVisibility() {

    const layer = this.layer();

    layer.setVisible(this.visible() ? false : true);
  }

}
