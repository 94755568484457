
<button [ngClass]="{'esk-tool--active' : active()===true}" [appTooltip]="'Draw ' + definition().drawType" class="esk-tool" (click)="active.set(!active())"><i [className]="definition().icon"></i></button>
@if (active()) {
  <button @slide-in appTooltip="Undo Last Point" class="esk-tool" (click)="undo()"><i class="fa fa-undo"></i></button>
  <button @slide-in appTooltip="Intersect with Property Boundary" [ngClass]="{'esk-tool--active' : intersect()===true}" (click)="intersect.set(!intersect())" class="esk-tool"><i class="fa fa-object-group"></i></button>
  <span  @slide-in class="flex flex-row items-center border border-gray-400 rounded-lg px-2 shadow-inner shadow-gray-500/30 bg-gray-100">
    <button
      appTooltip="Difference with Layers"
      [class.text-blue-600]="difference()"
      [ngClass]="{'esk-tool--active': difference() === true}"
      (click)="toggleDifference()"
      class="esk-tool text-gray-700 hover:text-blue-600 focus:outline-none"
    >
      <i    [class.text-blue-600]="difference()" class="fa fa-cut"></i>
    </button>
    <button
      appTooltip="Intersect Targets"

      [matMenuTriggerFor]="menu"
      class="menu-button text-gray-700 hover:text-blue-600 focus:outline-none ml-3"
    >
      <i class="fa fa-chevron-down chevron-icon"></i>
    </button>
  </span>

<mat-menu class="mat-menu" #menu="matMenu">
  <div class="flex flex-column">
    @for (item of _differenceTargetLayers(); track $index) {
      <mat-checkbox [formControl]="differenceTargets.get(item.name)" (click)="$event.stopPropagation()">{{item.name}}</mat-checkbox>
    }

  </div>
</mat-menu>

}
