import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input, model, output, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RuleTableComponent } from 'src/app/shared/components/style-renderer/rule-table/rule-table.component';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { environment } from 'src/environments/environment';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { DialogRef } from '@angular/cdk/dialog';
import { Result } from 'src/app/@core/events/Result';
import { generateComputedModel } from 'src/app/@core/utils/utils';
import { Style } from 'ol/style';

import {isEqual} from 'lodash';

@Component({
    selector: 'app-edit-project-layer',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        TooltipDirective,
        RuleTableComponent,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatSelectModule
    ],
    templateUrl: './edit-project-layer.component.html',
    styleUrl: './edit-project-layer.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditProjectLayerComponent {

  private readonly httpClient:HttpClient = inject(HttpClient);

  ref = inject(DialogRef<EditProjectLayerComponent>);  

  projectLayer = model.required();

  update = output<Result<{name?: string, style?: string}>>();

  style = signal(null);
  
  landUseTypes = toSignal(this.httpClient.get(environment.apiUrl + "land-use-type"))

  form: FormGroup = new FormGroup({
    name: new FormControl('', CustomValidators.required),
    kind: new FormControl({ value:  'Polygon', disabled: true }),
    isLandUse: new FormControl({ value: false, disabled: true }),
    landUseType: new FormControl({ value: null, disabled: true }),
    style: new FormControl()
  });

  model = generateComputedModel<{name?: string, style?: string}>({name: null, style: null}, this.form)



  /**
   *
   */
  constructor() {
   
    toObservable(this.projectLayer).pipe(takeUntilDestroyed()).subscribe((projectLayer: any) => 
    {
      this.form.patchValue({
        name: projectLayer.name, 
        kind: projectLayer.kind, 
        isLandUse: projectLayer.isLandUse,
        landUseType: projectLayer.landUseTypeID,
        style: JSON.stringify(projectLayer.style)
      },{emitEvent: false});

      this.style.set(projectLayer.style);
    }
    )

    effect(() => {
      const style = this.style();
      
      const savedStyle =  JSON.parse(this.form.get('style').getRawValue());

      var result = isEqual(style,savedStyle);

      if(result)
      {
        return;
      }

      this.form.get('style').setValue(JSON.stringify(style));
      this.form.get('style').markAsDirty();
    }); 


  }

  submit()
  {
    this.update.emit(Result.ok(this.model()))
  }

  cancel()
  {
    this.update.emit(Result.cancel())
  }





 }
