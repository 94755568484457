import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, signal, WritableSignal } from '@angular/core';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { DataCatalogueService } from '../data-catalogue/data-access/services/data-catalogue.service';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from 'src/app/shared/accordion/accordion.component';
import { AccordionItemComponent } from 'src/app/shared/accordion-item/accordion-item.component';
import { MapService } from '../../data-access/map.service';
import LayerGroup from 'ol/layer/Group';
import BaseLayer from 'ol/layer/Base';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { accordionAnimation, accordionAnimation2 } from '../data-catalogue/utils/animations/accordion.animation';
import { LayerGroupComponent } from './ui/layer-group/layer-group/layer-group.component';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import { Type } from 'ol/geom/Geometry';
import { LayersService } from './data-access/services/layers.service';
import TileLayer from 'ol/layer/Tile';
import { TileWMS } from 'ol/source';
import { VectorLayerComponent } from './ui/vector-layer/vector-layer.component';
import { ImageLayerComponent } from './ui/image-layer/image-layer.component';
import { CdkDrag, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { FeatureState, LayerState } from '../property/features/feature-layers/data-access/feature-layers.service';
import { TuiAccordion, TuiAccordionItem } from '@taiga-ui/kit';
import { LayerControlComponent } from 'src/app/shared/features/layer-control/layer-control.component';
import { LayerControlActionsDirective } from 'src/app/shared/features/layer-control/layer-control-actions.directive';
import { LayerControlPreviewDirective } from 'src/app/shared/features/layer-control/layer-control-preview.directive';
import { StopPropagationDirective } from 'src/app/shared/directives/stop-propagation.directive';
import { StyleRendererComponent } from 'src/app/shared/components/style-renderer/style-renderer.component';
import { LayerGroupControlComponent } from 'src/app/shared/features/layer-group-control/layer-group-control.component';
import { LayerLegendComponent } from 'src/app/shared/features/layer-legend/layer-legend.component';
import { LayerControlHeaderActionsDirective } from 'src/app/shared/features/layer-control/layer-control-header-actions.directive';
import { ActionPortalDirective } from 'src/app/shared/features/action-portal/directives/action-portal.directive';


export interface LayerDTO {
  layerID: number;
  name: string;
  area: number;
  style?: any;
  features: UserFeatureDTO[];
  state?: WritableSignal<LayerState>;
}

export interface UserFeatureDTO {
  featureID: number;
  name: string;
  geom: string;
  attributes: FeatureAttributes[];
  state?: WritableSignal<FeatureState>;
}

export interface FeatureAttributes {
  [key: string]: any;
}




@Component({
    selector: 'app-layers',
    templateUrl: './layers.component.html',
    styleUrls: ['./layers.component.css'],
    imports: [ActionPortalDirective, LayerGroupControlComponent, LayerControlHeaderActionsDirective, LayerLegendComponent, CommonModule, AccordionComponent, CdkAccordionModule, LayerGroupComponent, VectorLayerComponent, ImageLayerComponent, CdkDropList, CdkDrag, TuiAccordion, LayerControlComponent, LayerControlActionsDirective, LayerControlPreviewDirective, StopPropagationDirective, StyleRendererComponent],
    animations: [accordionAnimation2],
    host: {
        'class': 'px-2 py-4 inline-flex flex-grow-1 flex-col'
    }
})
export class LayersComponent {

  private readonly modalService: ModalService = inject(ModalService);
  public readonly mapService: MapService = inject(MapService);
  public readonly layersService: LayersService = inject(LayersService);

  count = 100;

  readonly catalogueService: DataCatalogueService =
    inject(DataCatalogueService);

    constructor(private cdr: ChangeDetectorRef) {

    }


    expandAccordion() {
      // Logic to expand the accordion
      this.cdr.detectChanges();
    }



  showDataCatalogue() {
    this.modalService.showDataCatalogue();
  }

  drop(event: any) {


    const originalIndex = this.layersService.test.length  - 1 - event.previousIndex;

    const reversedIndex = this.layersService.test.length  - 1 - event.currentIndex;

    moveItemInArray(this.layersService.test, originalIndex, reversedIndex);

    moveItemInArray(this.mapService.getMap().getLayers().getArray(), originalIndex + 1, reversedIndex + 1);


    this.mapService.getMap().render();

  }


}
