import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { afterNextRender, Component, inject, input, OnInit, signal, WritableSignal } from '@angular/core';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TuiDataList, TuiScrollbar, TuiSizeL, TuiSizeM, TuiSizeS } from '@taiga-ui/core';
import { TuiCheckbox, TuiDataListWrapper } from '@taiga-ui/kit';
import { NgxControlValueAccessor, provideCvaDefaultValue } from 'ngxtension/control-value-accessor';
import { distinctUntilChanged, map } from 'rxjs';
import { isEqual } from 'lodash';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { TuiSelectModule, TuiTextfieldControllerModule } from '@taiga-ui/legacy';
import { CalculateSelectHeightPipe, SelectHeightPipeOptions } from './calculate-select-height.pipe';
import { tuiPure, TuiStringHandler, TuiContext } from '@taiga-ui/cdk';
export interface SelectModel {
  displayName: string,
  id:string
}


@Component({
  selector: 'esk-select',
  hostDirectives: [NgxControlValueAccessor],
  imports:[ FormsModule,  TuiSelectModule,
    TuiDataListWrapper,
    TuiDataList,
  CdkVirtualScrollViewport,
  TuiTextfieldControllerModule,CdkFixedSizeVirtualScroll, CdkVirtualForOf, CalculateSelectHeightPipe],
  providers: [
    provideCvaDefaultValue(),
  ],
  template: `

    <tui-select
      [ngModel]="cva.value"
      [disabled]="cva.disabled$()"
      [valueContent]="models() ? stringify(models()) : 'loading'"
      (ngModelChange)="onChange($event)"
      [tuiTextfieldLabelOutside]="labelOutside()"
      [tuiTextfieldSize]="size()"
    >
    @if (!labelOutside()) {
      {{label()}}
    }
      <cdk-virtual-scroll-viewport
          *tuiDataList
          appendOnly
          tuiScrollable
          [itemSize]="44"
          [style.height.px]="(models()?.length + 1) ?? 1 | calculateSelectHeight : heightPipeOptions()"
      >
          <div class="p-1 sticky top-0">
            <ng-content></ng-content>
          </div>

          <tui-data-list>
          <button
                  tuiOption
                  type="button"
                  [value]="null"
              >
                Reset
              </button>
              <button
                  *cdkVirtualFor="let item of  models()"
                  tuiOption
                  type="button"
                  [value]="item.id"
                  [disabled]="selectedIDs().includes(item.id)"
              >
                  {{ item.displayName}}
              </button>
          </tui-data-list>
      </cdk-virtual-scroll-viewport>
    </tui-select>
  `,
  host:{
    class: 'min-w-[150px]'
  }
})
export class SelectComponent {

   protected cva = inject<NgxControlValueAccessor<string | null>>(
    NgxControlValueAccessor,
  );

  @tuiPure
  protected stringify(items: readonly SelectModel[]): TuiStringHandler<TuiContext<string>> {
      const map = new Map(
        items.map(
          ({ id, displayName }) =>
            [id, displayName] as [string, string]
        )
      );

      return ({$implicit}: TuiContext<string>) => map.get($implicit) || '';
  }
  protected onChange = (selectedID: string) =>
    (this.cva.value =
      this.models().find(({ id }) =>  id === selectedID)?.id ?? null);


  models = input.required<SelectModel[]>();

  selectedIDs = input<string[]>([]);

  size = input<TuiSizeL | TuiSizeM | TuiSizeS>('m')

  labelOutside = input<boolean>(false);
  label = input<string>('Default Label')


  heightPipeOptions: WritableSignal<SelectHeightPipeOptions> = signal<SelectHeightPipeOptions>({
    maxVisibleItems: 5, itemHeight: 44, contentBeforeHeight: 0, containerVerticalPadding: 4
  })
}
