import { Component, OnDestroy, OnInit, OutputEmitterRef, Renderer2, computed, effect, forwardRef, inject, input, model, output } from '@angular/core';
import { Modify, Select } from 'ol/interaction';
import { ModifyEvent } from 'ol/interaction/Modify';
import VectorSource from 'ol/source/Vector';
import { Collection, Feature, Map as olMap } from 'ol';
import { Style, Fill, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { SelectEvent } from 'ol/interaction/Select';
import { CommonModule } from '@angular/common';
import { ESKTOOL_TOKEN, EskTool } from '../../util/esk-tool-interface';
import { LineString, Point } from 'ol/geom';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';

@Component({
    selector: 'map-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.css'],
    imports: [CommonModule, TooltipDirective],
    providers: [
        { provide: ESKTOOL_TOKEN, useExisting: forwardRef(() => SelectComponent) }
    ]
})
export class SelectComponent implements OnInit, OnDestroy, EskTool {


  private readonly renderer:Renderer2 = inject(Renderer2);

  private readonly selectedFeatures = new Map<string, Feature>();

  private readonly unlistenFunctions = new Set<() => void>();

  delete = output<Array<Feature>>();

  active = model(false);

  map = input.required<olMap>();

  layers = input([]);

  selectInteraction = computed(() => {

      const layers = this.layers();

      const selectInteraction = new Select({
          layers: layers,
          style: selectStyle
      });

      selectInteraction.on('select', (event: SelectEvent) => {

        const selectedFeatures = event.selected;
        const deselectedFeatures = event.deselected;

        selectedFeatures.forEach(feature => {
          const id = feature.get('featureID');

          this.selectedFeatures.set(id, feature);

        });

        deselectedFeatures.forEach(feature => {
          const id = feature.get('featureID');

          this.selectedFeatures.delete(id,);

        });


      });

      return selectInteraction;

  });

  constructor() {

    effect(() => {

      const active = this.active();
      const selectInteraction = this.selectInteraction();

      if(selectInteraction == null)
      {
        return;
      }

        if(!active)
          {
            this.unlistenFunctions.forEach(unlistenFunction => {
              unlistenFunction();
            });

            this.map().removeInteraction(selectInteraction);
            return;

          }

          const deleteKeyListener = this.renderer.listen('document', 'keydown', this.handleDeleteKey.bind(this));
          this.unlistenFunctions.add(deleteKeyListener);

      this.map().addInteraction(selectInteraction);

    });

   }

  ngOnInit() {

    const deleteKeyListener = this.renderer.listen('document', 'keydown', this.handleDeleteKey.bind(this));
    this.unlistenFunctions.add(deleteKeyListener);

  }
  ngOnDestroy(): void {
    this.unlistenFunctions.forEach(unlistenFunction => {
      unlistenFunction();
    });

    this.selectedFeatures.clear();
    this.unlistenFunctions.clear();
  }

  private handleDeleteKey(event:KeyboardEvent)
  {


    if(event.key == 'Delete')
      {
          event.stopPropagation();
          event.preventDefault();

        this.delete.emit(Array.from(this.selectedFeatures.values()))
      }

  }

}


const selectStyle = (feature) => {
  const geometry = feature.getGeometry();
  let style;
  if (geometry instanceof Point) {
    style = new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({ color: 'yellow' }),
        stroke: new Stroke({ color: 'black', width: 1 }),
      }),
    });
  } else if (geometry instanceof LineString) {
    style = new Style({
      stroke: new Stroke({
        color: 'yellow',
        width: 4,
      }),
    });
  } else {
    style = new Style({
      stroke: new Stroke({
        color: 'yellow',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 0, 0.3)',
      }),
    });
  }
  return style;
};
