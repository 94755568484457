<app-dialog (close)="onClose()">
  <ng-template eskDialogHeader>
    <h3>Update Scenario</h3>
  </ng-template>
  <ng-template eskDialogContent>
    <form [formGroup]="layerForm" (ngSubmit)="onSubmit()">
      <section class="sticky top-0 bg-white z-10">
        <p class="text-xs text-gray-600 mb-6 leading-relaxed break-words whitespace-normal text-justify max-w-[50ch]">
          Choose a name and pick a list of layers to save as a Scenario for this property.
        </p>
        <tui-input formControlName="name">
          Type Scenario Name
          <input tuiTextfieldLegacy type="email" />
        </tui-input>

        <div class="flex items-center justify-start py-2.5 font-bold border-b border-gray-300">
          <label class="flex items-center gap-2">
            <input
            tuiCheckbox
            type="checkbox"
            [indeterminate]="indeterminate()"
            [ngModel]="allSelected()"
            (ngModelChange)="toggleSelectAll($event)"
            [ngModelOptions]="{standalone: true}"
          />
          Layers

          </label>
        </div>

      </section>



      <tui-scrollbar>
        <div overflowPadding class="max-h-[400px]">
          @for(layer of test(); track layer.layerID)
          {
            <div class="flex items-center justify-start py-2.5 border-b border-gray-200">
            <div class="flex-1 flex items-center gap-2 justify-between">

              <label class="flex items-center gap-2">
                <input
                tuiCheckbox
                type="checkbox"
                [ngModel]="layer.isSelected"
                (ngModelChange)="updateRow(layer.layerID, {isSelected: $event})"
                [ngModelOptions]="{standalone: true}"
              />
                {{ layer.name }}

              </label>

              <label class="flex items-center gap-2 align-end">
                <input
                tuiSwitch
                type="checkbox"
                   [ngModel]="layer.shouldClone"
                   (ngModelChange)="onCloneToggle(layer,$event)"
                   [ngModelOptions]="{standalone: true}"
            />
                Clone

              </label>
            </div>
          </div>
          }
        </div>
      </tui-scrollbar>

      <div class="flex justify-end mt-5">
        <button
          class="bg-gray-300 hover:bg-gray-400 text-gray-700 py-2 px-4 rounded mr-2"
          (click)="onClose()"
        >
          Cancel
        </button>
        <button
        [disabled]="layerForm.invalid"
          class="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded disabled:bg-blue-300 disabled:cursor-not-allowed"
          (click)="onSubmit()"
        >
          Confirm
        </button>
      </div>
    </form>
  </ng-template>
  <ng-template eskDialogFooter></ng-template>
</app-dialog>
