import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, computed, effect, Inject, inject, linkedSignal, OnInit, output, signal } from '@angular/core';
import { Ok, Result } from 'ts-results';
import { FeatureLayersService, LayerDTO } from '../../data-access/feature-layers.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective } from 'src/app/shared/features/dialog';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TuiCheckbox, TuiSwitch, tuiSwitchOptionsProvider,  } from '@taiga-ui/kit';

import {TuiInputModule} from '@taiga-ui/legacy';
import { CreateScenarioRequest } from '../../data-access/scenario/models/models';
import { filter, map, switchMap } from 'rxjs';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { CloneFeatureLayerComponent } from '../../ui/clone-feature-layer/clone-feature-layer.component';
import { filterNil } from 'ngxtension/filter-nil';
import { CloneFeatureLayerRequest } from '../../ui/create-feature-layer/create-feature-layer.component';
import { result } from 'lodash';
import { Layer } from 'ol/layer';
import { TuiScrollbar } from '@taiga-ui/core';
import { OverflowPaddingDirective } from 'src/app/shared/directives/overflow-padding.directive';
import { SelectionModel } from '@angular/cdk/collections';
import { Prettify } from 'src/app/shared/features/esk-tool/util';

export type ExtendedLayerDTO = Prettify<LayerDTO & {
  isSelected: boolean;
  shouldClone: boolean;
  cloneName: string;
}>;


@Component({
    selector: 'app-update-scenario',
    templateUrl: './create-scenario.component.html',
    styleUrls: ['./create-scenario.component.css'],
    imports: [CommonModule, ReactiveFormsModule, FormsModule, TuiCheckbox, TuiSwitch, TuiInputModule,TuiScrollbar,OverflowPaddingDirective, DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective, TuiSwitch],
     changeDetection:ChangeDetectionStrategy.OnPush
})
export class CreateScenarioComponent implements OnInit {

  private readonly dialogRef: DialogRef<Result<CreateScenarioRequest | void, Error>,CreateScenarioComponent> = inject(DialogRef<Result<{} | void, Error>,CreateScenarioComponent>);

  private readonly featureLayerService: FeatureLayersService = inject(FeatureLayersService);
  private readonly modalService = inject(ModalService)


  propertyLayers = toSignal(this.featureLayerService.GetAll(null,{select: ['LayerID','Name']}),{initialValue:[]});

  allSelected = computed(() => {
    const items = this.test();
    const total = items.length;
    const selectedCount = items.filter(i => i.isSelected).length;

    return selectedCount === total && total > 0;
  });

  test = linkedSignal<ExtendedLayerDTO[]>(
    () => {
      const propertyLayers = this.propertyLayers();

      return propertyLayers.map(layer => ({
        ...layer,
        isSelected: true,
        shouldClone: false,
        cloneName:''
      }))
    }

  )

  indeterminate = computed(() => {
    const items = this.test();
    const total = items.length;
    const selectedCount = items.filter(i => i.isSelected).length;
    return selectedCount >= 2 && selectedCount < total;
  });

  submitModel = computed(() => {
  const rows = this.test();

  return rows.filter(row => row.isSelected).map(({layerID,shouldClone,cloneName}) => ({layerID, shouldClone, cloneName}))
  })

  toggleSelectAll(selected:boolean)
  {
    const items = this.test();


    const updatedItems = items.map(item => ({
      ...item,
      isSelected: selected,
    }));

    this.test.set(updatedItems);
  }

  updateRow(layerID: number, payload: Partial<ExtendedLayerDTO>) {

  const items = this.test();


  const index = items.findIndex(e => e.layerID === layerID);


  if (index !== -1) {
    const updatedItem = { ...items[index], ...payload };

    const updatedItems = [
      ...items.slice(0, index),
      updatedItem,
      ...items.slice(index + 1)
    ];

    this.test.set(updatedItems);

  }
}

onCloneToggle(layer:ExtendedLayerDTO,shouldClone:boolean)
{

      this.updateRow(layer.layerID, {shouldClone});

      if(!shouldClone)
        return;

  this.modalService.showComponent<CloneFeatureLayerComponent, Result<CloneFeatureLayerRequest | null, Error>>(CloneFeatureLayerComponent,layer).closed.pipe(
    filter(result => result.ok),
    map(result => result.val)).subscribe(result => {
      if(result == null)
      {
          this.updateRow(layer.layerID, {shouldClone:false});
          return;
      }

      const {layerName} = result as CloneFeatureLayerRequest;

      this.updateRow(layer.layerID, {cloneName: layerName, shouldClone})
})
}

  controlGroups = computed(() => {
    const layers = this.propertyLayers();
    const controls = [];

    layers.forEach(layer => {
      const control =  this.createLayerControl(layer);
      controls.push(control);
    })

    return controls;
  })

  controlsAdded = effect(() => {

    const controls = this.controlGroups();
    const selectedLayersControl = this.layerForm.get('selectedLayers') as FormArray;

    controls.forEach((control) => {
      selectedLayersControl.push(control)
    });

  });

  layerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    selectedLayers: new FormArray([])
  });

  constructor(private fb: FormBuilder, @Inject(DIALOG_DATA) public data: {propertyID: string}) {


    effect(() => {
      console.log(this.test())
    })
  }


  private createLayerControl(layer:LayerDTO) {


    const control = new FormGroup({
      layer: new FormControl(layer),
      isSelected: new FormControl(true),
      shouldClone: new FormControl(false),
      cloneName: new FormControl('')
    });

    control.controls.shouldClone.valueChanges.pipe(filter(clone => clone),switchMap(() => {
         return this.modalService.showComponent<CloneFeatureLayerComponent, Result<CloneFeatureLayerRequest | null, Error>>(CloneFeatureLayerComponent,layer).closed.pipe(
            filter(result => result.ok),
            map(result => result.val))
    })).subscribe(result => {
      if(result == null)
      {
        control.controls.shouldClone.setValue(false, {emitEvent: false});
      }

      const {layerName} = result as CloneFeatureLayerRequest;

        control.patchValue({cloneName: layerName})
    })

    return control;
  }

  get selectedLayers(): FormArray {
    return this.layerForm.get('selectedLayers') as FormArray;
  }


  ngOnInit() {
  }

  onClose()
  {
    this.dialogRef.close(Ok.EMPTY);
  }

  onSubmit(): void {


    const request: CreateScenarioRequest = {
      name: this.layerForm.get('name')?.value,
      featureLayers: this.submitModel()
    };

      this.dialogRef.close(Ok(request));
  }


}
