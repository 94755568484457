import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, computed, effect, Inject, inject, linkedSignal, OnInit, output, signal, WritableSignal } from '@angular/core';
import { Ok, Result } from 'ts-results';
import { FeatureLayersService, LayerDTO } from '../../data-access/feature-layers.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective } from 'src/app/shared/features/dialog';
import { FormArray, FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TuiCheckbox, TuiSwitch,  } from '@taiga-ui/kit';

import {TuiInputModule} from '@taiga-ui/legacy';
import { CreateScenarioRequest, GetScenarioRequest, UpdateScenarioRequest } from '../../data-access/scenario/models/models';
import { filter, map } from 'rxjs';
import { CloneFeatureLayerComponent } from '../clone-feature-layer/clone-feature-layer.component';
import { CloneFeatureLayerRequest } from '../create-feature-layer/create-feature-layer.component';
import { ExtendedLayerDTO } from '../create-scenario/create-scenario.component';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { TuiScrollbar } from '@taiga-ui/core';

@Component({
    selector: 'app-update-scenario',
    templateUrl: './update-scenario.component.html',
    styleUrls: ['./update-scenario.component.css'],
    imports: [CommonModule, ReactiveFormsModule,FormsModule, TuiCheckbox,TuiScrollbar, TuiSwitch, TuiInputModule, DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective]
})
export class UpdateScenarioComponent implements OnInit {

  private readonly dialogRef: DialogRef<Result<UpdateScenarioRequest | void, Error>,UpdateScenarioComponent> = inject(DialogRef<Result<{} | void, Error>,UpdateScenarioComponent>);

  private readonly featureLayerService: FeatureLayersService = inject(FeatureLayersService);

    private readonly modalService = inject(ModalService)


  selectedScenario:WritableSignal<GetScenarioRequest> = signal(null);

  propertyLayers = toSignal(this.featureLayerService.GetAll(null,{select: ['LayerID','Name']}),{initialValue:[]});
  allSelected = computed(() => {
    const items = this.test();
    const total = items.length;
    const selectedCount = items.filter(i => i.isSelected).length;

    return selectedCount === total && total > 0;
  });

  test = linkedSignal<ExtendedLayerDTO[]>(
    () => {
      const propertyLayers = this.propertyLayers();
      const selectedScenario = this.selectedScenario();

      if(selectedScenario == null)
        return [];
      const {featureLayers} = selectedScenario;

      return propertyLayers.map(layer => ({
        ...layer,
        isSelected: featureLayers.includes(layer.layerID),
        shouldClone: false,
        cloneName:''
      }))
    }

  )

  indeterminate = computed(() => {
    const items = this.test();
    const total = items.length;
    const selectedCount = items.filter(i => i.isSelected).length;
    return selectedCount >= 2 && selectedCount < total;
  });

  submitModel = computed(() => {
  const rows = this.test();

  return rows.filter(row => row.isSelected).map(({layerID,shouldClone,cloneName}) => ({layerID, shouldClone, cloneName}))
  })

  toggleSelectAll(selected:boolean)
  {
    const items = this.test();


    const updatedItems = items.map(item => ({
      ...item,
      isSelected: selected,
    }));

    this.test.set(updatedItems);
  }

  updateRow(layerID: number, payload: Partial<ExtendedLayerDTO>) {

  const items = this.test();


  const index = items.findIndex(e => e.layerID === layerID);


  if (index !== -1) {
    const updatedItem = { ...items[index], ...payload };

    const updatedItems = [
      ...items.slice(0, index),
      updatedItem,
      ...items.slice(index + 1)
    ];

    this.test.set(updatedItems);

  }
}

onCloneToggle(layer:ExtendedLayerDTO,shouldClone:boolean)
{

      this.updateRow(layer.layerID, {shouldClone});

      if(!shouldClone)
        return;

  this.modalService.showComponent<CloneFeatureLayerComponent, Result<CloneFeatureLayerRequest | null, Error>>(CloneFeatureLayerComponent,layer).closed.pipe(
    filter(result => result.ok),
    map(result => result.val)).subscribe(result => {
      if(result == null)
      {
          this.updateRow(layer.layerID, {shouldClone:false});
          return;
      }

      const {layerName} = result as CloneFeatureLayerRequest;

      this.updateRow(layer.layerID, {cloneName: layerName, shouldClone})
})
}


  layerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    selectedLayers: new FormArray([])
  });

  constructor(private fb: FormBuilder, @Inject(DIALOG_DATA) public data: {selectedScenario: GetScenarioRequest}) {

    this.selectedScenario.set(data.selectedScenario);

    effect(() => {
     const selectedScenario = this.selectedScenario();

     if(selectedScenario == null)
      return;

     const {name} = selectedScenario;

      this.layerForm.get('name').setValue(name);

    })
  }


  ngOnInit() {
  }

  onClose()
  {
    this.dialogRef.close(Ok.EMPTY);
  }

  onSubmit(): void {


    const request: UpdateScenarioRequest = {
      name: this.layerForm.get('name')?.value,
      featureLayers: this.submitModel()
    };

      this.dialogRef.close(Ok(request));
  }


}
