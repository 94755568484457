import { Directive, HostListener, input } from '@angular/core';
import BaseLayer from 'ol/layer/Base';

@Directive({
  selector: '[LayerVisibilityControl]',
  standalone:true
})
export class LayerVisibilityDirective {

  layer =  input.required<BaseLayer>();
  currentVisibleState = input.required<string>()

  constructor() { }

  @HostListener('click', ['$event'])
  onClick(event:MouseEvent)
  {
    event.preventDefault();
   // event.stopPropagation();

    if(this.currentVisibleState() == 'visible' || this.currentVisibleState() == 'partial')
    {
      this.layer().setVisible(false);
    }
    else
    {
      this.layer().setVisible(true);
    }

  }
}
