

<ng-template actionPortal>
  <div class="grid grid-cols-3 px-2 items-center flex-grow-1">

    <h3 [tuiSkeleton]="!propertiesState.propertiesLoaded()" class="justify-self-center text-base font-semibold text-gray-800 text-wrap text-center  col-start-2" >{{propertiesState.activeProperty()?.propertyName ?? 'Property'}}</h3>

    <div class="justify-self-end col-start-3 space-x-2">
      <button position="left"   appTooltip="Edit Property" class="text-lg text-gray-400 p-0 m-0 hover:text-gray-600 transition-colors focus:outline-none" (click)="propertiesState.edit(propertyID())" id="editButton">
        <i class="fa fa-pencil"></i>
      </button>
      <button position="left"   appTooltip="Delete Property" class="text-lg text-gray-400 p-0 m-0 hover:text-red-500 transition-colors focus:outline-none" (click)="propertiesState.delete(propertyID())">
        <i class="fa fa-trash"></i>
      </button>
    </div>

  </div>
</ng-template>

  <div class="property-details">

    <div class="header flex flex-column gap--md">



      <p class="property-address" [tuiSkeleton]="!propertiesState.activeProperty()">
        {{ property()?.propertyAddress }}
      </p>

      <span class="status-badge" [tuiSkeleton]="!propertiesState.activeProperty()">
        {{ property()?.status }}
      </span>

      <div class="actions" [tuiSkeleton]="!propertiesState.activeProperty()">
        <button (click)="onClick()">Property Layers</button>

        <button [disabled]="isZooming()" (click)="zoomTo()">
          @if (isZooming()) {
            <tui-loader size="xs" [inheritColor]="true"></tui-loader>
          }
          @else {
            <i class="fa fa-magnifying-glass"></i>
          }
          Zoom To
        </button>

        <button (click)="export(property()?.propertyID)">
          <i class="fa fa-download"></i>
          Download
        </button>

        <a routerLink="/create-pdf/print">
          <i class="fa fa-print"></i> Print PDF
        </a>
      </div>


      <div        [tuiSkeleton]="!propertiesState.activeProperty()" class="w-full flex items-center space-x-2">
        <esk-select class="flex-grow" [models]="views()" label="Property View" [formControl]="viewControl"></esk-select>
        <button

          (click)="createView()"
          class="grid place-items-center text-white bg-blue-500 hover:bg-blue-600 rounded-full w-10 h-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-200 ease-in-out shadow-md hover:shadow-lg"
          [tuiSkeleton]="!propertiesState.activeProperty()"
        >
          <i class="fa fa-plus"></i>
        </button>
        <button
        [tuiSkeleton]="propertiesState.activeProperty() == null"
        [disabled]="viewControlDisabled()"
  class="grid place-items-center text-white bg-yellow-500 hover:bg-yellow-600 disabled:bg-gray-400 disabled:cursor-not-allowed rounded-full w-10 h-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition duration-200 ease-in-out shadow-md hover:shadow-lg"
>
  <i class="fa fa-pencil"></i>
</button>
      </div>
    </div>

    <table  [tuiSkeleton]="!propertiesState.activeProperty()" class="property-table">
      <!-- Basic Information Section -->
      <tr>
        <th class="section-heading" colspan="2">
          <h2>
            <span class="fa fa-circle-info button-icon"></span>
            Basic Information
          </h2>
        </th>
      </tr>
      <tr>
        <td><strong>Owner Name</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.propertyOwnerName }}
        </td>
      </tr>
      <tr>
        <td><strong>Project Name</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.projectName }}
        </td>
      </tr>
      <tr>
        <td><strong>Process Leader</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.processLeader }}
        </td>
      </tr>

      <!-- Property Details Section -->
      <tr>
        <th colspan="2">
          <h2>
            <span class="fa fa-location-dot button-icon"></span>
            Property Details
          </h2>
        </th>
      </tr>
      <tr>
        <td><strong>Area (Ha)</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.areaHaDisplay }}
        </td>
      </tr>
      <tr>
        <td><strong>Zone</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.zone }}
        </td>
      </tr>

      <!-- Value Section -->
      <tr>
        <th colspan="2">
          <h2>
            <span class="fa fa-dollar-sign button-icon"></span>
            Value
          </h2>
        </th>
      </tr>
      <tr>
        <td><strong>Price</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.price }}
        </td>
      </tr>
      <tr>
        <td><strong>Primary Class</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.primaryClass }}
        </td>
      </tr>
      @if (property()?.secondaryClass) {
        <tr>
          <td><strong>Secondary Class</strong></td>
          <td [tuiSkeleton]="!propertiesState.activeProperty()">
            {{ property()?.secondaryClass }}
          </td>
        </tr>
      }

      <!-- Source Information Section -->
      <tr>
        <th colspan="2">
          <h2>
            <span class="fa fa-sitemap button-icon"></span>
            Source Information
          </h2>
        </th>
      </tr>
      <tr>
        <td><strong>Source Name</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.sourceName }}
        </td>
      </tr>
      <!--
      <tr>
        <td><strong>Source Email</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.sourceEmail }}
        </td>
      </tr>
      -->

      <!-- Emissions Section -->
      <tr>
        <th colspan="2">
          <h2>
            <span class="fa fa-chart-line button-icon"></span>
            Emissions
          </h2>
        </th>
      </tr>
      <tr>
        <td><strong>Total Emissions</strong></td>
        <td >
          {{ property()?.totalEmissions }}
        </td>
      </tr>
      <tr>
        <td><strong>Environmental Certification</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.environmentalCertification }}
        </td>
      </tr>
      <tr>
        <td><strong>Last Emissions Assessment</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.emissionAssessmentYear | date }}
        </td>
      </tr>

      <!-- Historical Data Section -->
      <tr>
        <th colspan="2">
          <h2>
            <span class="fa fa-clock-rotate-left button-icon"></span>
            Historical Data
          </h2>
        </th>
      </tr>
      <!--
      <tr>
        <td><strong>Listing Date</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.listingDate | date }}
        </td>
      </tr>
      -->
      <tr>
        <td><strong>Created At</strong></td>
        <td [tuiSkeleton]="!propertiesState.activeProperty()">
          {{ property()?.createdAt | date }}
        </td>
      </tr>
    </table>

    <!-- Optional: Additional Sections with Skeletons -->
    <!--
    <div class="section" [tuiSkeleton]="!propertiesState.activeProperty()">
      <h2>Basic Information</h2>
      <p><strong>Property ID: </strong> <span>{{property()?.propertyID}}</span></p>
      <p><strong>Owner Name: </strong> <span>{{property()?.propertyOwnerName}}</span></p>
    </div>

    <div class="section" [tuiSkeleton]="!propertiesState.activeProperty()">
      <h2>Property Details</h2>
      <p><strong>Area (Ha): </strong> <span>{{property()?.areaHaDisplay}}</span></p>
      <p><strong>Zone: </strong> <span>{{property()?.zone}}</span></p>
    </div>

    <div class="section" [tuiSkeleton]="!propertiesState.activeProperty()">
      <h2>Geographical Information</h2>
      <p><strong>State: </strong> <span>{{property()?.state}}</span></p>
      <p><strong>Region: </strong> <span>{{property()?.region}}</span></p>
    </div>

    <div class="section" [tuiSkeleton]="!propertiesState.activeProperty()">
      <p><strong>Price: </strong> <span>{{property()?.price}}</span></p>
      <p><strong>Primary Class: </strong> <span>{{property()?.primaryClass}}</span></p>
      @if (property()?.secondaryClass) {
        <p><strong>Secondary Class:</strong> <span>{{property()?.secondaryClass}}</span></p>
      }
    </div>

    <div class="section" [tuiSkeleton]="!propertiesState.activeProperty()">
      <p><strong>Source Name: </strong> <span>{{property()?.sourceName}}</span></p>
      <p><strong>Source Email: </strong> <span>{{property()?.sourceEmail}}</span></p>
    </div>

    <div class="section" [tuiSkeleton]="!propertiesState.activeProperty()">
      <h2>Historical Data</h2>
      <p><strong>Listing Date: </strong> <span>{{property()?.listingDate | date}}</span></p>
      <p><strong>Created At: </strong> <span>{{property()?.createdAt | date}}</span></p>
    </div>
    -->
  </div>
