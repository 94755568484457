import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap, identity } from 'rxjs';
function rxEffect(source, effectOrOptions, options) {
  const effect = effectOrOptions && 'destroyRef' in effectOrOptions ? undefined : effectOrOptions;
  options ??= effect ? options : effectOrOptions;
  return source.pipe(effect ? tap(effect) : identity, takeUntilDestroyed(options?.destroyRef)).subscribe();
}

/**
 * Generated bundle index. Do not edit.
 */

export { rxEffect };
