import { CdkAccordion, CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { accordionAnimation } from 'src/app/map/features/data-catalogue/utils/animations/accordion.animation';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.css'],
    imports: [CommonModule, CdkAccordionModule],
    animations: [accordionAnimation]
})
export class AccordionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
