import { CommonModule } from '@angular/common';
import { Component, computed, effect, Inject, inject, linkedSignal, OnInit, output } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators, FormsModule } from '@angular/forms';
import { CatalogueLayerWithState } from '../../../data-catalogue/data-access/models/catalogue-data.model';
import { CreatePropertyViewDTO, CreatePropertyViewLayerDTO } from '../../data-access/models/property-view-model';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiCheckbox, TuiSwitch,  } from '@taiga-ui/kit';
import { DataCatalogueService } from '../../../data-catalogue/data-access/services/data-catalogue.service';
import { toSignal } from '@angular/core/rxjs-interop';
import {TuiInputModule} from '@taiga-ui/legacy';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Ok, Result } from 'ts-results';
import { DialogComponent } from 'src/app/shared/features/dialog/dialog.component';
import { DialogContentDirective } from 'src/app/shared/features/dialog/directives/dialog-content.directive';
import { DialogFooterDirective } from 'src/app/shared/features/dialog/directives/dialog-footer.directive';
import { DialogHeaderDirective } from 'src/app/shared/features/dialog/directives/dialog-header.directive';
import { Prettify } from 'src/app/shared/features/esk-tool/util';
import { TuiScrollbar } from '@taiga-ui/core';
import { OverflowPaddingDirective } from 'src/app/shared/directives/overflow-padding.directive';

export type ExtendedViewLayer = Prettify<CatalogueLayerWithState & {
  isSelected: boolean;
  opacity: number,
  visible: boolean
}>;


@Component({
    selector: 'app-CreatePropertyView',
    templateUrl: './CreatePropertyView.component.html',
    styleUrls: ['./CreatePropertyView.component.css'],
    imports: [CommonModule, ReactiveFormsModule,FormsModule,TuiCheckbox, TuiSwitch,TuiScrollbar,OverflowPaddingDirective, TuiInputModule, DialogComponent, DialogContentDirective, DialogFooterDirective, DialogHeaderDirective]
})
export class CreatePropertyViewComponent implements OnInit {

  submit = output<Result<CreatePropertyViewDTO, Error>>()

  onClose()
  {
    this.dialogRef.close();
  }

  private readonly catalogueService:DataCatalogueService = inject(DataCatalogueService);
  private readonly dialogRef: DialogRef<CreatePropertyViewComponent> = inject(DialogRef<CreatePropertyViewComponent>);

  layers = toSignal(this.catalogueService.addedLayers$);

    allSelected = computed(() => {
      const items = this.test();
      const total = items.length;
      const selectedCount = items.filter(i => i.isSelected).length;

      return selectedCount === total && total > 0;
    });

    test = linkedSignal<ExtendedViewLayer[]>(
      () => {
        const propertyLayers = this.layers();
        console.log(propertyLayers)

        return propertyLayers.map(layer => ({
          ...layer,
          isSelected: true,
          visible: layer.layer.getVisible(),
          opacity: layer.layer.getOpacity()
        }))
      }

    )

    indeterminate = computed(() => {
      const items = this.test();
      const total = items.length;
      const selectedCount = items.filter(i => i.isSelected).length;
      return selectedCount >= 2 && selectedCount < total;
    });

    submitModel = computed<CreatePropertyViewLayerDTO[]>(() => {
    const rows = this.test();

    return rows.filter(row => row.isSelected).map<CreatePropertyViewLayerDTO>(({dataCatalogueLayerID,visible,opacity}) => ({dataCatalogueLayerID,isVisible: visible,opacity}))
    })

    toggleSelectAll(selected:boolean)
    {
      const items = this.test();


      const updatedItems = items.map(item => ({
        ...item,
        isSelected: selected,
      }));

      this.test.set(updatedItems);
    }

    updateRow(layerID: number, payload: Partial<ExtendedViewLayer>) {

    const items = this.test();


    const index = items.findIndex(e => e.dataCatalogueLayerID === layerID);


    if (index !== -1) {
      const updatedItem = { ...items[index], ...payload };

      const updatedItems = [
        ...items.slice(0, index),
        updatedItem,
        ...items.slice(index + 1)
      ];

      this.test.set(updatedItems);

    }
  }

  controlGroups = computed(() => {
    const layers = this.layers();
    const controls = [];

    layers.forEach(layer => {
      const control =  this.createLayerControl(layer);
      controls.push(control);
    })

    return controls;
  })

  controlsAdded = effect(() => {

    const controls = this.controlGroups();
    const selectedLayersControl = this.layerForm.get('selectedLayers') as FormArray;

    controls.forEach((control) => {
      selectedLayersControl.push(control)
    });

  });

  layerForm: FormGroup;

  constructor(private fb: FormBuilder, @Inject(DIALOG_DATA) public data: {propertyID: string}) {
    this.layerForm = this.fb.group({
      name: this.fb.control('', Validators.required),
      selectedLayers: this.fb.array([]),
    });
  }

  ngOnInit(): void {
  }


  private createLayerControl(layer: CatalogueLayerWithState) {
    const control = new FormGroup({
      layer: new FormControl(layer),
      isSelected: new FormControl(true),
      isVisible:new FormControl( layer.layer.getVisible()),
      opacity: new FormControl(layer.layer.getOpacity())
    });

    return control;
  }

  get selectedLayers(): FormArray {
    return this.layerForm.get('selectedLayers') as FormArray;
  }

  onSubmit(): void {

    const selectedLayerData = this.submitModel();

    const createPropertyViewDTO: CreatePropertyViewDTO = {
      propertyID: this.data.propertyID,
      name: this.layerForm.get('name')?.value,
      layers: selectedLayerData
    };

      this.submit.emit(Ok(createPropertyViewDTO));
  }
}

