<cdk-accordion-item
  class="dc-accordion-item"
  role="button"
  [ngClass]="{ collapsed: accordion().expanded }"
  [ngClass]="{ expanded: accordion().expanded }"
>
  <div
    cdkDragHandle
    class="dc-accordion-item-header"
    (click)="accordion().toggle()"
    [ngClass]="{ 'sticky-header': accordion().expanded }"
  >
    <span>
      <button
        LayerVisibilityControl
        [layer]="layer().layer"
        [currentVisibleState]="layer().visible"
        class="feature-layer-accordion-item-button"
        [disabled]="!layer().canActivate"
      >
        @switch (layer().visible) { @case ('visible') {
        <i class="fa fa-eye"></i>
        } @case ('partial') {
        <i class="fa fa-low-vision"></i>
        } @case ('hidden') {
        <i class="fa fa-eye-slash"></i>
        } @default {
        <i class="fa fa-eye"></i>
        } }
      </button>
      {{ layer().title }}
    </span>

    <div class="flex gap--sm align-center">
      @if(layer().hasRemoveFn) {
      <button class="button button--icon" (click)="layer().removeFn($event)">
        <span><i class="fa fa-trash"></i></span>
      </button>
      }
      <span>
        @if (accordion().expanded) {
        <i class="fa-solid fa-chevron-up"></i>
        } @else {
        <i class="fa-solid fa-chevron-down"></i>
        }
      </span>
    </div>
  </div>
  <div
    class="dc-accordion-item-body"
    role="region"
    [@slideInOut]="accordion().expanded ? 'in' : 'out'"
  >
    <div class="flex flex-column justify-center align-start gap--xs">
      <label
        >Opacity:<input
          autocomplete="off"
          data-lpignore="true"
          #opacityInput
          type="range"
          min="0"
          max="1"
          step="0.01"
          layerOpacityControl
          [layer]="layer().layer"
          [value]="layer().opacity"
      /></label>
      @if (layer().legendURL && layer().sourceType != 'ArcGISRest') {
      <img [src]="layer().legendURL" alt="" srcset="" />
      } @else if(layer().legendURL && layer().sourceType == 'ArcGISRest') { @for
      (legendItem of legend(); track $index) {
      <span class="flex align-center gap--md"
        ><img [src]="legendItem.imageSrc" alt="" />{{ legendItem.label }}</span
      >
      } }
    </div>
  </div>
</cdk-accordion-item>
