import { Directive, HostListener, input } from '@angular/core';
import BaseLayer from 'ol/layer/Base';

@Directive({
  selector: '[layerOpacityControl]',
  standalone: true,
})
export class LayerOpacityDirective {

   layer =  input.required<BaseLayer>();

   constructor() { }

   @HostListener('mousedown', ['$event'])
   onMousedown(event: MouseEvent): void {
     event.stopPropagation();
   }

   @HostListener('input', ['$event'])
   onInput(event: Event): void {
     //event.preventDefault();
     //event.stopPropagation();

     const inputElement = event.target as HTMLInputElement;
     const opacityValue = parseFloat(inputElement.value);
     this.layer().setOpacity(opacityValue);
     this.layer().changed();
   }
 }

