import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, effect, forwardRef, inject, input, model } from '@angular/core';
import { Feature, Map as OlMap } from 'ol';
import { Circle, Geometry, LineString, Point, Polygon } from 'ol/geom';
import { Draw, Select } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { fromLonLat, toLonLat } from 'ol/proj';
import { DrawType } from '../measure/measure-settings/measure-settings.component';
import { Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { SelectEvent } from 'ol/interaction/Select';
import {
  CalculateArea,
  Intersect,
  Union,
} from 'src/app/shared/helpers/transformations';
import {
  platformModifierKey,
  click,
  pointerMove,
  platformModifierKeyOnly,
  doubleClick,
} from 'ol/events/condition.js';
import { MapService } from '../../data-access/map.service';
import { Style, Fill, Stroke, Text } from 'ol/style';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { ComponentFactory } from 'src/app/@core/services/ComponentFactory/component-factory.service';
import { ESKTOOL_TOKEN, EskTool } from '../../util/esk-tool-interface';
import { ToolbarComponent } from 'src/app/shared/components/toolbar/toolbar.component';

const ctrlClickCondition = (event) =>
  platformModifierKeyOnly(event) && click(event);

const createAreaStyle = (feature) => {
  const area = feature.get('area');
  return new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.25)',
    }),
    stroke: new Stroke({
      color: '#000',
      width: 2,
      lineDash: [2, 5],
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: '#000',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
      text: area ? area.toFixed(2) + ' ha' : '',
    }),
  });
};

@Component({
    selector: 'app-pivot-measure',
    templateUrl: './pivot-measure.component.html',
    styleUrls: ['./pivot-measure.component.css'],
    imports: [CommonModule, TooltipDirective, ToolbarComponent],
    providers: [
        { provide: ESKTOOL_TOKEN, useExisting: forwardRef(() => PivotMeasureComponent) }
    ]
})
export class PivotMeasureComponent implements OnInit, OnDestroy, EskTool {
  clear() {
    this.pivotLayer.getSource().clear();
  }
  private readonly mapService: MapService = inject(MapService);
  private readonly componentFactoryService: ComponentFactory =
    inject(ComponentFactory);
  pivotLayer = new VectorLayer({
    source: new VectorSource(),
    style: createAreaStyle,
  });
  selectSource = new VectorSource();
  selectLayer = new VectorLayer({
    source: this.selectSource,
  });
  selectInteraction: Select = null;

  drawSource = new VectorSource();
  drawLayer = new VectorLayer({ source: this.drawSource });

  drawInteraction: Draw = null;

  active = model<boolean>();

  activeEffect = effect(() => {

    const active = this.active();

    if(!active && this.drawInteraction != null)
      {
        this.deSelect();
        this.mapService.setupMapEvents();
      }
    else if(active && this.drawInteraction == null )
    {
      this.mapService.removeMapEvents();
      this.init();
    }
  },{allowSignalWrites:true});


  confirm() {

    this.active.set(false);
    const features = this.selectInteraction.getFeatures().getArray();

    let feature = Union(features);

    const area = CalculateArea(feature, { projection: 'EPSG:3857' });
    feature.set('area', area);
    feature.setStyle(null);

    this.selectSource.clear();

    this.pivotLayer.getSource().addFeature(feature);
    this.mapService.map.removeInteraction(this.selectInteraction);
    this.mapService.map.removeLayer(this.selectLayer);
    this.mapService.map.addLayer(this.pivotLayer);
  }

  cancel()
  {
    this.deSelect();
    this.init();
  }

  constructor() {}
  ngOnDestroy(): void {
    this.mapService.setupMapEvents();
    this.deSelect();
  }

  deSelect() {
    this.mapService.map.removeInteraction(this.selectInteraction);
    this.selectSource.clear();
    this.drawSource.clear();
    this.mapService.map.removeLayer(this.selectLayer);
    this.mapService.map.removeInteraction(this.drawInteraction);
    this.mapService.map.removeLayer(this.drawLayer);
    this.drawInteraction = null;
  }

  ngOnInit() {}

  init() {
    this.mapService.map.addLayer(this.drawLayer);
    this.drawInteraction = new Draw({
      type: 'Circle',
      source: this.drawSource,
    });
    this.mapService.map.addInteraction(this.drawInteraction);

    this.drawInteraction.on('drawend', (e: DrawEvent) => {
      if (e.feature == null) {
        return;
      }
      let feature = e.feature as Feature<Circle>;

      const center = feature.getGeometry().getCenter();

      let segments = this.createSegmentPolygons(
        center,
        feature.getGeometry().getRadius(),
        12
      );
      this.selectSource.addFeatures(segments);

      this.mapService.map.removeInteraction(this.drawInteraction);
      this.mapService.map.removeLayer(this.drawLayer);
      this.mapService.map.addLayer(this.selectLayer);
      this.selectInteraction = new Select({
        layers: [this.selectLayer],
      });

      this.mapService.map.addInteraction(this.selectInteraction);

    });
  }

  createSegmentPolygons(center, radius, numSegments) {
    const features = [];
    const degreesPerSegment = 360 / numSegments;
    const radiansPerSegment = (Math.PI / 180) * degreesPerSegment;

    for (let i = 0; i < numSegments; i++) {
      const segmentStartAngle = radiansPerSegment * i;
      const segmentEndAngle = segmentStartAngle + radiansPerSegment;

      const segmentPoints = [];
      segmentPoints.push(center);

      const steps = 20;
      for (let step = 0; step <= steps; step++) {
        const theta =
          segmentStartAngle +
          (segmentEndAngle - segmentStartAngle) * (step / steps);
        const x = center[0] + radius * Math.cos(theta);
        const y = center[1] + radius * Math.sin(theta);
        segmentPoints.push([x, y]);
      }

      segmentPoints.push(center);

      const polygon = new Polygon([segmentPoints]);
      const feature = new Feature(polygon);
      features.push(feature);
    }

    return features;
  }
}
