import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'property-page-container',
    template: `<router-outlet></router-outlet>`,
    imports: [CommonModule, RouterModule]
})
export class PropertyPageContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
