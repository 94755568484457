import { Component, OnDestroy, inject, input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EditPropertyComponent } from '../../features/edit-property/edit-property.component';
import { SidebarService } from '../../../sidebar/data-access/sidebar.service';
import { MaterialModule } from 'src/app/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PropertiesTableComponent } from '../../features/properties-table/properties-table.component';
import { PropertiesState } from '../../data-access/state';
import {TuiLoader, tuiLoaderOptionsProvider} from '@taiga-ui/core';

@Component({
    selector: 'app-bottom-sheet',
    templateUrl: './bottom-sheet.component.html',
    styleUrls: ['./bottom-sheet.component.css'],
    imports: [MaterialModule, SharedModule, TuiLoader]
})
export class BottomSheetComponent implements OnDestroy {
  sidebarService = input.required<SidebarService>();
  propertyState = inject(PropertiesState);

  constructor(private bottomSheet: MatBottomSheet) {}
  ngOnDestroy(): void {
    this.bottomSheet.dismiss();
  }

  openBottomSheet(): void {
    this.sidebarService().close();
    this.bottomSheet.open(PropertiesTableComponent, {
      hasBackdrop: false,
      panelClass: 'test',
      data: { sidebar: this.sidebarService() },
    });
  }
}
