<app-dialog (close)="onClose()">
  <ng-template eskDialogHeader><h3>Create View</h3></ng-template>
  <ng-template eskDialogContent>

    <form [formGroup]="layerForm" (ngSubmit)="onSubmit()">



  <section class="sticky top-0 bg-white z-10">
    <p class="subtitle-text">Choose a name and pick a list of data catalogue layers to save as a view for this property.</p>
    <tui-input formControlName="name">
      Type View Name
      <input
          tuiTextfieldLegacy
          type="email"
      />
  </tui-input>

    <div class="flex items-center justify-start py-2.5 font-bold border-b border-gray-300">
      <label class="flex items-center gap-2">
        <input
        tuiCheckbox
        type="checkbox"
        [indeterminate]="indeterminate()"
        [ngModel]="allSelected()"
        (ngModelChange)="toggleSelectAll($event)"
        [ngModelOptions]="{standalone: true}"
      />
      Catalogue Layers

      </label>
    </div>

  </section>

  <tui-scrollbar>
    <div overflowPadding class="max-h-[400px]">
      @for (layer of test(); track layer.dataCatalogueLayerID) {
        <div class="flex items-center justify-start py-2.5 border-b border-gray-200">
          <div class="flex-1 flex items-center gap-2 justify-between">
            <label class="flex items-center gap-2">
              <input
                tuiCheckbox
                type="checkbox"
                [ngModel]="layer.isSelected"
                (ngModelChange)="updateRow(layer.dataCatalogueLayerID, {isSelected: $event})"
                [ngModelOptions]="{standalone: true}"
              />
              {{ layer.name }}
            </label>

            <label class="flex items-center gap-2 align-end">
              <input
                tuiSwitch
                type="checkbox"
                [ngModel]="layer.visible"
                (ngModelChange)="onVisibilityToggle(layer, $event)"
                [ngModelOptions]="{standalone: true}"
              />
              Visible
            </label>
          </div>
        </div>
      }
    </div>
  </tui-scrollbar>

  </form></ng-template>
  <ng-template eskDialogFooter>  <button class="button button--muted" (click)="onClose()">Cancel</button>
    <button class="button button--blue" [disabled]="layerForm.invalid" (click)="onSubmit()">Confirm</button></ng-template>
</app-dialog>
