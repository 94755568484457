import { Pipe, PipeTransform } from '@angular/core';

export interface SelectHeightPipeOptions {
  maxVisibleItems: number;
  itemHeight: number;
  contentBeforeHeight: number;
  containerVerticalPadding?: number;
  itemGap?: number;
}

@Pipe({
  name: 'calculateSelectHeight'
})
export class CalculateSelectHeightPipe implements PipeTransform {
  protected readonly defaultTUIButtonPadding = 2;

  /**
   * Calculates the best height for a dropdown of items. Returns necessary height
   * in px for displaying maxVisibleItems, otherwise the reduced height
   * to fit the number of items.
   *
   * @param {any[]} items - items to be displayed in the select dropdown
   * @param {Object} options
   * @param {number} options.maxVisibleItems - max number of items to display at once
   * @param {number} options.itemHeight - height in px for each item
   * @param {number} options.contentBeforeHeight - height of contentBefore in px
   * @param {number} [options.containerVerticalPadding] - any padding vertical applied to the container in px
   * @param {number} [options.itemGap] - any manual items gap in px
   * @returns height of select dropdown box
   */
  transform(count: number, options: SelectHeightPipeOptions): unknown {
    return (
      Math.min(count, options.maxVisibleItems) * (options.itemHeight + (this.defaultTUIButtonPadding * 2)) +
      options.contentBeforeHeight +
      (options.containerVerticalPadding ?? 0) * 2 +
      (options.itemGap ?? 0) * (count - 1)
    );
  }

}
